
//import React from 'react';

import { useState, useEffect } from 'react';

import FractionBlock, { generateBlockData as fractionBlockGenerateData, settings as fractionBlockSettings } from './Components/FractionBlock';
import DaysOfTheWeekBlock, { generateBlockData as daysOfTheWeekBlockGenerateData, settings as daysOfTheWeekBlockSettings } from './Components/DaysOfTheWeekBlock';
import MonthBlock, { generateBlockData as monthBlockGenerateData, settings as monthBlockSettings } from './Components/MonthBlock';
import PictogramSimpleBlock, { generateBlockData as pictogramSimpleBlockGenerateData, settings as pictogramSimpleBlockSettings } from './Components/PictogramSimpleBlock';
import RomanNumeralsBlock, { generateBlockData as romanNumeralsBlockGenerateData, settings as romanNumeralsBlockSettings } from './Components/RomanNumeralsBlock';
import MultiplyBlock, { generateBlockData as multiplyBlockGenerateData, settings as multiplyBlockSettings } from './Components/MultiplyBlock';
import CubeVolumeBlock, { generateBlockData as cubeVolumeBlockGenerateData, settings as cubeVolumeBlockSettings } from './Components/CubeVolumeBlock';
import DivisionWashingPowderBlock, { generateBlockData as divisionWashingPowderBlockGenerateData, settings as divisionWashingPowderBlockSettings } from './Components/DivisionWashingPowderBlock';
import TimetableBlock, { generateBlockData as timetableBlockGenerateData, settings as timetableBlockSettings } from './Components/TimetableBlock';
import ThinkingOfNumberBlock, { generateBlockData as thinkingOfNumberBlockGenerateData, settings as thinkingOfNumberBlockSettings } from './Components/ThinkingOfNumberBlock';

import BlockCard from './Components/BlockCard';
import AddBlockForm from './Components/AddBlockForm';
import Fraction from './Components/Fraction';

import randomInt from './Utils/randomInt';
import shuffle from './Utils/shuffle';

import defaultBlockData from './json/defaultBlockData.json';

import './scss/App.scss';

import SemiCircle from './Images/semi-circle.svg';
import FullCircle from './Images/full-circle.svg';
import QuarterCircle from './Images/quarter-circle.svg';


// acts as a whitelist of blocks and holds the generateData function
const blockMap = {
  FractionBlock: { name: 'Fraction', component: FractionBlock, generateData: fractionBlockGenerateData, settings: fractionBlockSettings },
  DaysOfTheWeekBlock: { name: 'Days of the week', component: DaysOfTheWeekBlock, generateData: daysOfTheWeekBlockGenerateData, settings: daysOfTheWeekBlockSettings },
  MonthBlock: { name: 'Months of the year', component: MonthBlock, generateData: monthBlockGenerateData, settings: monthBlockSettings },
  PictogramSimpleBlock: { name: 'Pictogram', component: PictogramSimpleBlock, generateData: pictogramSimpleBlockGenerateData, settings: pictogramSimpleBlockSettings },
  RomanNumeralsBlock: { name: 'Roman numerals', component: RomanNumeralsBlock, generateData: romanNumeralsBlockGenerateData, settings: romanNumeralsBlockSettings },
  MultiplyBlock: { name: 'Muliplication', component: MultiplyBlock, generateData: multiplyBlockGenerateData, settings: multiplyBlockSettings },
  CubeVolumeBlock: { name: 'Cube Volume', component: CubeVolumeBlock, generateData: cubeVolumeBlockGenerateData, settings: cubeVolumeBlockSettings },
  DivisionWashingPowderBlock: { name: 'Division Washing Powder', component: DivisionWashingPowderBlock, generateData: divisionWashingPowderBlockGenerateData, settings: divisionWashingPowderBlockSettings },
  TimetableBlock: { name: 'Timetable', component: TimetableBlock, generateData: timetableBlockGenerateData, widthMultiplier: 2, settings: timetableBlockSettings },
  ThinkingOfNumberBlock: { name: 'Thinking of a number', component: ThinkingOfNumberBlock, generateData: thinkingOfNumberBlockGenerateData, settings: thinkingOfNumberBlockSettings },
};

let initialBlockData = defaultBlockData;
// set initial block data - either loaded from localstorage or use default data
(function(){
  let modifiedBlockData;
  
  if(localStorage.getItem("blocks") !== null){
    try {
      const savedBlocks = JSON.parse(localStorage.getItem("blocks"));
      if(Array.isArray(savedBlocks)){
        initialBlockData = savedBlocks;
        console.log('loaded saved data', savedBlocks);
      }
    } catch(e){
      // parse error - invalid json
    }
  }

  // if failed to parse or nothing to load then use the default block data
  // if(!modifiedBlockData){
  //   modifiedBlockData = defaultBlockData;
  //   console.log('loading DEFAULT data');
  // }
})();

function App() {

  const generateAllBlockData = (modifiedBlockData) => {
    // go through the blocks and generate any random values needed. defaultBlockData can be replaced by loaded data from a save
    const out = modifiedBlockData.map(blockData => {

      if(typeof blockMap[blockData.type] != undefined){
        if(!blockData.dataFilled){
          return { ...blockMap[blockData.type].generateData(blockData), dataFilled: true };
        }
      } else {
        console.log('Block not whitelisted in default function');
        return {};
      }

      return blockData; // return the data as-is if we haven't updated and returned the updated one
    });

    return out;
  };

  let latestBlockId = 0;
  // set unique ids on blocks

  // duplicate code - could refactor to a func
  let modifiedBlockData = initialBlockData.map((blockData, index) => {
    latestBlockId++;
    return { ...blockData, id: latestBlockId };
  });

  modifiedBlockData = generateAllBlockData(modifiedBlockData);

  const [showAnswers, setShowAnswers] = useState(false);
  const [blocks, setBlocks] = useState(modifiedBlockData);
  const [addBlockModalShowing, setAddBlockModalShowing] = useState(false);
  const [minimalist, setMinimalist] = useState(false);
  const [showAnswerBoxes, setShowAnswerBoxes] = useState(false);

  const addBlockFormDefault = {
    selectedBlock: { key: '', name: '' },
    settingValues: {}
  };
  const [addBlockFormState, setAddBlockFormState] = useState(addBlockFormDefault);
  const addBlockFormBlockList = Object.keys(blockMap).map(key => ({ key, name: blockMap[key].name, settings: blockMap[key].settings }));

  const addBlockFormSelectBlock = (blockType) => {
    // needs to change the settings option list and values to those of the selected block
    // add settings to addBlockFormDefault
    //console.log('ADDING settings', blockMap[blockType.key].settings);

    // const settings = blockMap[blockType.key].settings ? blockMap[blockType.key].settings : [];
    // console.log('SETTINGS', settings);

    //console.log('read set', blockMap[blockType.key].settings)
    let defaultSettings = {};
    blockMap[blockType.key].settings.forEach(s => {
      defaultSettings[s.key] = s.value;
    });
    //console.log('set default settings', defaultSettings)

    setAddBlockFormState(prevState => {
      //return { ...prevState, selectedBlock: { ...blockType }, settings: [ ...settings ] };
      return { ...prevState, selectedBlock: { ...blockType }, settingValues: defaultSettings  };
    });
  };

  const addBlockFormSelectSetting = ({ key, value }) => {
    //console.log('changed', key, value)

    // const settings = addBlockFormState.settings.map(s => {
    //   if(s.key == key){
    //     return { ...s, value };
    //   }
    //   return s;
    // });

    let updatedSettings = { ...addBlockFormState.settingValues };
    updatedSettings[key] = value;

    setAddBlockFormState(prevState => {
      return { ...prevState, settingValues: updatedSettings };
    });

    //console.log('updated sets', addBlockFormState.settingValues)
  };

  const addBlockFormReset = () => {
    setAddBlockFormState({ ...addBlockFormDefault });
  };
  const addBlockFormClose = () => {
    addBlockFormReset();
    setAddBlockModalShowing(false);
  };
  
  const addBlockFormSubmit = () => {

    //const blockType = "RomanNumeralsBlock";
    let blockData = {
      "type": addBlockFormState.selectedBlock.key,
      "dataFilled": false,
      "showAnswer": false
    };

    // handle settings
    blockData.settings = { ...addBlockFormState.settingValues };

    blockData = { ...blockMap[blockData.type].generateData(blockData), dataFilled: true };
    //latestBlockId++;
    //blockData.id = latestBlockId;

    // find next block id, don't use latestBlockId as that's only for the initial setting of ids on the first json list
    let highestBlockId = 0;
    blocks.forEach(b => {
      if(b.id > highestBlockId){
        highestBlockId = b.id;
      }
    });
    blockData.id = highestBlockId+1;

    setBlocks(prevBlocks => {
      console.log('updated blocks', [...prevBlocks, blockData])
      return [...prevBlocks, blockData];
    });

    addBlockFormClose();
  };

  const closeBlock = (id) => {
    setBlocks(prevBlocks => {
      return prevBlocks.filter(blockData => { return blockData.id != id; });
    });
  };

  const refreshBlock = (id) => {
    setBlocks(prevBlocks => {
      const newBlocksData = prevBlocks.map(blockData => {
        if(blockData.id != id){
          return blockData;
        }

        // run the generate function
        return blockMap[blockData.type].generateData(blockData);
      });

      console.log('newBlocks', newBlocksData);
      return newBlocksData;
    });
  };

  const showAnswer = (id) => {
    setBlocks(prevBlocks => {
      return prevBlocks.map(blockData => {
        return blockData.id == id ? { ...blockData, showAnswer: !blockData.showAnswer } : blockData;
      });
    });
  };

  const moveBlock = (id, direction) => {

    // first find the index
    let index = 0;
    blocks.forEach((b, i) => {
      if(b.id == id){
        index = i;
        return false;
      }
    });

    if ((direction == 'left' && index != 0) || (direction == 'right' && index != blocks.length-1)) {
      let previousBlocks = [...blocks];
      let temp;

      if(direction == 'left'){
        temp = previousBlocks[index - 1];
        previousBlocks[index - 1] = previousBlocks[index];
        previousBlocks[index] = temp;
      } else {
        // right
        temp = previousBlocks[index + 1];
        previousBlocks[index + 1] = previousBlocks[index];
        previousBlocks[index] = temp;
      }

      setBlocks(previousBlocks);
    }


  };


  const refreshAll = () => {
    setBlocks(prevBlocks => {
      const newBlocksData = prevBlocks.map(blockData => {

        // run the generate function
        return blockMap[blockData.type].generateData(blockData);
      });

      return newBlocksData;
    });
  };

  const randomiseSort = () => {
    // mix up the sort order

    setBlocks(prevBlocks => {
      const shuffledBlocks = [...prevBlocks];
      shuffle(shuffledBlocks);

      return shuffledBlocks;
    });
  };

  const save = () => {
    const blocksToSave = blocks.map(block => {

      // find the base block obj in defaultBlockData (so we don't save any values or generated data)
      const baseBlockObj = defaultBlockData.find(b => {
        return b.type === block.type;
      });

      // to save settings, will have to make a copy of block and set dataFilled:false
      // that will save all values/settings but generate new data on load.
      // save the data as the default data for that block, not the current generated data
      // might add a feature later to save the gen'd data as well

      const defaultData = { ...baseBlockObj.data }; // use the default block data
      return { ...block, showAnswer: false, dataFilled: false, data: defaultData }
    });

    localStorage.setItem("blocks", JSON.stringify(blocksToSave));
    console.log(blocksToSave);
  };

  // clears saved block data and pulls in the default set
  const resetBlockData = () => {
    localStorage.removeItem("blocks");

    // duplicate code - could refactor to a func
    let modifiedBlockData = defaultBlockData.map((blockData, index) => {
      latestBlockId++;
      return { ...blockData, id: latestBlockId };
    });

    modifiedBlockData = generateAllBlockData(modifiedBlockData);

    setBlocks(modifiedBlockData);
  };

  const handleToggleAnswers = (e) => {
    setBlocks(prevBlocks => {
      return prevBlocks.map(blockData => {
        return { ...blockData, showAnswer: e.target.checked };
      });
    });
  };

  const handleToggleMinimalist = (e) => {
    setMinimalist(e.target.checked);
  };

  const handleToggleAnswerBoxes = (e) => {
    setShowAnswerBoxes(e.target.checked);
  };

  return (
    <div className={`ma-cont${minimalist ? ' ma-minimalist' : ''}`}>

      {/*
      <img src={SemiCircle} width="50" />
      <img src={FullCircle} width="50" />
      <img src={QuarterCircle} width="50" />
      */}

      <div className="container-fluid">

        {/* <div><i className="bi-list-check" onClick={toggleAllAnswers}></i> Toggle Answers</div> */}

        {/* <a herf="javascript:void(0);" onClick={() => { setShowAnswers(!showAnswers) }}>{showAnswers ? 'Show Answers' : 'Hide Answers'}</a> */}
        
        <div className="col-xs-12">
          <div className="ma-toolbar">
            <button className="btn-icon" title="Add Block" onClick={() => setAddBlockModalShowing(true)}><i className="bi-plus"></i></button> 
            <button className="btn-icon" title="Refresh All Blocks" onClick={() => refreshAll()}><i className="bi-arrow-clockwise"></i></button>
            <button className="btn-icon" title="Randomly Sort Blocks" onClick={() => randomiseSort()}><i className="bi-shuffle"></i></button>
            <button className="btn-icon" title="Save Blocks" onClick={() => save()}><i className="bi-floppy"></i></button>
            <button className="btn-icon" title="Reset Blocks to Default" onClick={() => resetBlockData()}><i className="bi-trash3"></i></button>

            <div className="ma-togglebar">
              <div className="ma-toggle-cont"><span>Answers</span><div className="ma-toggle"><input type="checkbox" id="ma-toggle-answers" onChange={handleToggleAnswers} /><label htmlFor="ma-toggle-answers">Toggle</label></div></div>
              <div className="ma-toggle-cont"><span>Answer Boxes</span><div className="ma-toggle"><input type="checkbox" id="ma-toggle-answerboxes" onChange={handleToggleAnswerBoxes} /><label htmlFor="ma-toggle-answerboxes">Toggle</label></div></div>
              <div className="ma-toggle-cont"><span>Minimalist</span><div className="ma-toggle"><input type="checkbox" id="ma-toggle-minimalist" onChange={handleToggleMinimalist} /><label htmlFor="ma-toggle-minimalist">Toggle</label></div></div>
            </div>

          </div>
        </div>

        <div className="ma-block-cont">
        <div className="row">

          {blocks.map((blockData, index) => {

            let Block;
            let data = {};
            const isFirst = index == 0;
            const isLast = index == blocks.length-1;

            if(typeof blockMap[blockData.type] != 'undefined'){
              Block = blockMap[blockData.type].component;
            } else {
              console.log('Block not whitelisted');
              return (<></>);
            }

            return (
              <BlockCard
                block={Block}
                blockData={blockData}
                key={index}
                index={index}
                isFirst={isFirst}
                isLast={isLast}
                widthMultiplier={blockMap[blockData.type].widthMultiplier}
                showAnswerBoxes={showAnswerBoxes}

                /* method callbacks */
                showAnswer={showAnswer}
                refreshBlock={refreshBlock}
                closeBlock={closeBlock}
                moveBlock={moveBlock}

              />
            );
          })}

          {/* <Block>
            <FractionBlock data={fractionBlockData} />
          </Block>

          <Block>
            <DaysOfTheWeekBlock />
          </Block>

          <Block>
            <MonthBlock />
          </Block>
          */}

        </div>
        </div>

      </div>

      {addBlockModalShowing == false ? null : <AddBlockForm addBlockFormClose={addBlockFormClose} addBlockFormState={addBlockFormState} addBlockFormBlockList={addBlockFormBlockList} addBlockFormSelectBlock={addBlockFormSelectBlock} addBlockFormSelectSetting={addBlockFormSelectSetting} addBlockFormSubmit={addBlockFormSubmit} />}

    </div>
  );}

export default App;

import randomInt from '../Utils/randomInt';
import AnswerArea from './AnswerArea';

export const settings = [];

// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {
    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };

    const operations = ['+', '-', '*', '/'];
    const operationsNoDivide = ['+', '-', '*'];

    data.value = randomInt(10, 150); // this is the starting number
    data.firstOperation = operations[randomInt(0, 3)];
    //data.firstOperation = '/'; // temp
    data.firstOperand = randomInt(2, 9);

    // when dividing, overwrite the start number with a multiple of the operand
    // to ensure the number can be divided without decimals/remainders
    if(data.firstOperation == '/') {
      data.value = data.firstOperand * randomInt(3,9);
    }

    data.secondOperation = operationsNoDivide[randomInt(0, 2)];
    data.secondOperand = randomInt(2, 9);

    // maybe remove the first op so it doesn't get chosen for second op

    /*

    This is 2023 Reasoning 1, Q10 Ken thinks of a number...
    https://assets.publishing.service.gov.uk/media/646626e00b72d3001334476e/2023_key_stage_2_mathematics_Paper_2_reasoning.pdf
    

    todo
    when dividing, need to generate a whole multiple so we don't end up with decimals e.g.
        Ken is thinking of a number
        He / 5
        The answer is 20.2
        ^ avoid this
    */

    updatedBlockData.data = data;
    return updatedBlockData;
};

const executeOperation = (number, operation, number2) => {
  let out = 0;
  switch(operation){
    case "+":
      out = number + number2;
      break;
    case "-":
      out = number - number2;
      break;
    case "*":
      out = number * number2;
      break;
    case "/":
      out = number / number2;
      break;
  }
  return out;
};

const getOperationText = (operation, operand) => {

  let out = '';

  switch(operation){
    case '+':
      out = `He adds ${operand} to it`;
      break;
    case '-':
      out = `He subtracts ${operand} from it`;
      break;
    case '*':
      out = `He multiplies it by ${operand}`;
      break;
    case '/':
      out = `He divides it by ${operand}`;
      break;
  }

  return out;
};

const ThinkingOfNumberBlock = ({ data, showAnswerBoxes }) => {

    const {
      firstOperation,
      firstOperand,
      secondOperation,
      secondOperand,
      value
    } = data.data;

    
    let firstResult = executeOperation(value, firstOperation, firstOperand);
    let secondResult = executeOperation(firstResult, secondOperation, secondOperand);
    const answer = value;

    //console.log('TEST', executeOperation(1, '*', 2))

    const firstOperationText = getOperationText(firstOperation, firstOperand);
    const secondOperationText = getOperationText(secondOperation, secondOperand);

   // const answer = 5;

    return (

        <div>
          Ken thinks of a number.<br /><br />
          {/* He {firstOperation} {firstOperand}<br />
          He {secondOperation} {secondOperand}<br /> */}

          {firstOperationText}<br />
          {secondOperationText}<br />

          The answer is {secondResult}<br /><br />
          What number was Ken thinking of?

            <AnswerArea showAnswer={data.showAnswer} answer={answer} showAnswerBoxes={showAnswerBoxes} />
        </div>

    );

};

export default ThinkingOfNumberBlock;